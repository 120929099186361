import { useEffect } from 'react';
import '../index.css';
import packageJson from '../package.json'


function MyApp({ Component, pageProps }) {
	useEffect(() => {
		console.log(
			`\n%c \u{00A9} Sergei Peterburgskiy v${packageJson.version} \n\n`,
			"color: #000; background: #C4D058; padding:5px 0;"
		)
	}, [])

	return <Component {...pageProps} />;
}


export default MyApp;
